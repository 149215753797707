import { Table as AntdTable } from 'antd';
import { useAtomValue } from 'jotai';

import Table from '@/components/Table';
import { UNIT_TYPE } from '@/constants/unit';
import { useUnitCompositionRates } from '@/hooks/queries/partner';
import { selectedBranchIdAtom } from '@/store/atoms';

const ShareComposition = () => {
  const selectedBranchId = useAtomValue(selectedBranchIdAtom);
  const getUnitCompositionRates = useUnitCompositionRates(
    selectedBranchId as number,
    { enabled: selectedBranchId !== null }
  );

  return (
    <Table
      columns={[
        {
          title: '유닛 타입',
          dataIndex: 'unitType',
          render: (value) => UNIT_TYPE[value as keyof typeof UNIT_TYPE],
        },
        {
          title: '가로(mm)',
          dataIndex: 'width',
        },
        {
          title: '깊이(mm)',
          dataIndex: 'depth',
        },
        {
          title: '높이(mm)',
          dataIndex: 'height',
        },
        {
          title: '수량',
          dataIndex: 'sumCount',
          render: (value) => `${value}개`,
        },
        {
          title: '구성 비율',
          dataIndex: 'compositionRate',
          render: (value) => `${value}%`,
        },
      ]}
      dataSource={getUnitCompositionRates.data}
      summary={(data) => {
        const sumCount = data.reduce((acc, row) => {
          return acc + row.sumCount;
        }, 0);
        const compositionRate = data.reduce((acc, row) => {
          return acc + row.compositionRate * 100;
        }, 0);

        return (
          <AntdTable.Summary.Row>
            <AntdTable.Summary.Cell index={1}>총 개수</AntdTable.Summary.Cell>
            <AntdTable.Summary.Cell colSpan={3} index={2} />
            <AntdTable.Summary.Cell index={3}>
              {sumCount}개
            </AntdTable.Summary.Cell>
            <AntdTable.Summary.Cell index={4}>
              {compositionRate / 100 > 100 ? 100 : compositionRate / 100}%
            </AntdTable.Summary.Cell>
          </AntdTable.Summary.Row>
        );
      }}
      rowKey={(row) => row.unitType}
    />
  );
};

export default ShareComposition;
