import { Table as AntdTable } from 'antd';
import { useAtomValue } from 'jotai';

import Table from '@/components/Table';
import TooltipText from '@/components/TooltipText';
import { UNIT_TYPE } from '@/constants/unit';
import { useUnitOperationRates } from '@/hooks/queries/partner';
import { selectedBranchIdAtom } from '@/store/atoms';

const ShareOperation = () => {
  const selectedBranchId = useAtomValue(selectedBranchIdAtom);
  const getUnitOperationRates = useUnitOperationRates(
    selectedBranchId as number,
    { enabled: selectedBranchId !== null }
  );

  return (
    <Table
      columns={[
        {
          title: '일별',
          dataIndex: 'unitType',
          render: (value) => UNIT_TYPE[value as keyof typeof UNIT_TYPE],
        },
        {
          title: '가로(mm)',
          dataIndex: 'width',
        },
        {
          title: '깊이(mm)',
          dataIndex: 'depth',
        },
        {
          title: '높이(mm)',
          dataIndex: 'height',
        },
        {
          title: (
            <TooltipText
              description={
                '사용 중인 유닛의 개수를 기준으로,\n유닛 타입별 가동률을 나타냅니다'
              }
            >
              가동률
            </TooltipText>
          ),
          dataIndex: 'rate',
          render: (value) => `${value}%`,
        },
      ]}
      dataSource={getUnitOperationRates.data?.operationRates}
      summary={(data) => {
        return (
          <AntdTable.Summary.Row>
            <AntdTable.Summary.Cell index={1}>총 가동률</AntdTable.Summary.Cell>
            <AntdTable.Summary.Cell colSpan={3} index={2} />
            <AntdTable.Summary.Cell index={3}>
              {getUnitOperationRates.data?.totalOperationRate || 0}%
            </AntdTable.Summary.Cell>
          </AntdTable.Summary.Row>
        );
      }}
      rowKey={(row) => row.unitType}
    />
  );
};

export default ShareOperation;
