import type { GetBranchSharesParams } from '@/types/partner';

const queryKeys = {
  partner: {
    getMyInformation: () => ['getMyInformation'],
    getMyPermissions: () => ['getMyPermissions'],
    getBranchShares: (params: GetBranchSharesParams) => [
      'getBranchShares',
      params,
    ],
    getUnitOperationRates: (branchId: number) => [
      'getUnitOperationRates',
      branchId,
    ],
    getUnitCompositionRates: (branchId: number) => [
      'getUnitCompositionRates',
      branchId,
    ],
    getAdjustments: () => ['getAdjustments'],
  },
};

export default queryKeys;
