export const CONTRACT_STATUS = {
  RESERVED: '예약/이용중',
  NOT_PAID: '미납',
  CONTRACT_END: '계약 종료',
  CANCELED: '계약 취소',
} as const;

export const CUSTOMER_TYPE = {
  NORMAL: '개인',
  BUSINESS: '법인',
} as const;
