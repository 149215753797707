import { Tooltip } from 'antd';
import type { ReactNode } from 'react';
import tw from 'twin.macro';

import { TooltipIcon } from '@/assets/svg';

interface Props {
  description: string;
  children: ReactNode;
}

const TooltipText = ({ description, children }: Props) => {
  return (
    <span
      css={tw`flex flex-row items-center gap-[8px] text-inherit [font-weight:inherit] leading-[inherit]`}
    >
      {children}
      <Tooltip
        overlayInnerStyle={{
          padding: '4px 16px',
          textAlign: 'center',
          whiteSpace: 'nowrap',
        }}
        title={
          <>
            {description.split('\n').map((item, index) => (
              <span key={index}>
                {item}
                {index < description.length - 1 && <br />}
              </span>
            ))}
          </>
        }
      >
        <TooltipIcon />
      </Tooltip>
    </span>
  );
};

export default TooltipText;
