import { Button } from 'antd';
import tw from 'twin.macro';

import { NoticeIcon } from '@/assets/svg';
import Notice from '@/components/Notice';
import Table from '@/components/Table';
import { useAdjustments } from '@/hooks/queries/partner';

const SettlementIndex = () => {
  const getUnitOperationRates = useAdjustments();

  return (
    <div css={[tw`flex flex-col gap-[32px]`, tw`[td > .ant-btn]:(my-[-5px])`]}>
      <Notice icon={<NoticeIcon />}>
        자세한 정산 내역은 루커스튜디오를 통해 확인해주세요
      </Notice>

      <Table
        columns={[
          { title: '지점명', dataIndex: 'branchName', width: '50%' },
          {
            title: '정산 내역',
            dataIndex: 'lookerStudioLink',
            width: '50%',
            render: (_, row) => (
              <Button
                type="default"
                target="_blank"
                disabled={!row.lookerStudioLink}
                href={row.lookerStudioLink}
              >
                새 창에서 보기
              </Button>
            ),
          },
        ]}
        dataSource={getUnitOperationRates.data}
        rowKey={(row) => row.id}
      />
    </div>
  );
};

export default SettlementIndex;
