import { Layout } from 'antd';
import { useAtomValue } from 'jotai';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import tw from 'twin.macro';

import { PATH } from '@/Route';
import { authAtom } from '@/store/atoms';

interface Props {
  children?: ReactNode;
}

const GuestLayout = ({ children }: Props) => {
  const navigate = useNavigate();
  const auth = useAtomValue(authAtom);

  useEffect(() => {
    if (auth.accessToken) {
      navigate('/', { replace: true });
    }
  }, [auth]);

  return (
    <Layout>
      <Layout.Content css={tw`flex justify-center items-center bg-[#edeef1]`}>
        {children}
      </Layout.Content>
    </Layout>
  );
};

export default GuestLayout;
