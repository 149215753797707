import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import * as partnerApi from '@/apis/partner';
import type { Pagination } from '@/types/api';
import type { CommonUseQueryOptions } from '@/types/app';
import type {
  Adjustment,
  BranchShare,
  GetBranchSharesParams,
  GetMyInformationResponse,
  GetMyPermissionsResponse,
  GetUnitOperationRateResponse,
  UnitCompositionRate,
} from '@/types/partner';
import queryKeys from '@/utils/queryKeys';

export const useMyInformation = (
  options?: CommonUseQueryOptions<GetMyInformationResponse>
) => {
  const query = useQuery({
    queryKey: queryKeys.partner.getMyInformation(),
    queryFn: () => partnerApi.getMyInformation(),
    ...options,
  });

  return query;
};

export const useMyPermissions = (
  options?: CommonUseQueryOptions<GetMyPermissionsResponse>
) => {
  const query = useQuery({
    queryKey: queryKeys.partner.getMyPermissions(),
    queryFn: () => partnerApi.getMyPermissions(),
    ...options,
  });

  const availableMenuKeys = useMemo(() => {
    return Object.values(query.data?.permissions || {}).flatMap((group) =>
      Object.values(group).flat()
    );
  }, [query.data]);

  return { ...query, availableMenuKeys };
};

export const useBranchShares = (
  params: GetBranchSharesParams,
  options?: CommonUseQueryOptions<Pagination<BranchShare>>
) => {
  const query = useQuery({
    queryKey: queryKeys.partner.getBranchShares(params),
    queryFn: () => partnerApi.getBranchShares(params),
    ...options,
  });

  return query;
};

export const useUnitOperationRates = (
  branchId: number,
  options?: CommonUseQueryOptions<GetUnitOperationRateResponse>
) => {
  const query = useQuery({
    queryKey: queryKeys.partner.getUnitOperationRates(branchId),
    queryFn: () => partnerApi.getUnitOperationRates(branchId),
    ...options,
  });

  return query;
};

export const useUnitCompositionRates = (
  branchId: number,
  options?: CommonUseQueryOptions<UnitCompositionRate[]>
) => {
  const query = useQuery({
    queryKey: queryKeys.partner.getUnitCompositionRates(branchId),
    queryFn: () => partnerApi.getUnitCompositionRates(branchId),
    ...options,
  });

  return query;
};

export const useAdjustments = (
  options?: CommonUseQueryOptions<Adjustment[]>
) => {
  const query = useQuery({
    queryKey: queryKeys.partner.getAdjustments(),
    queryFn: () => partnerApi.getAdjustments(),
    ...options,
  });

  return query;
};
