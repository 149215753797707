import { Table as AntdTable, Typography } from 'antd';
import type { TableProps } from 'antd';
import tw from 'twin.macro';

interface Pagination {
  page: number;
  size: number;
  total?: number;
  setPaginationValues: ({ page, size }: { page: number; size: number }) => void;
}
interface Props<RecordType> {
  columns: TableProps<RecordType>['columns'];
  dataSource: TableProps<RecordType>['dataSource'];
  rowKey: TableProps<RecordType>['rowKey'];
  summary?: TableProps<RecordType>['summary'];
  pagination?: Pagination;
}

const Table = <RecordType,>({
  columns,
  dataSource,
  rowKey,
  summary,
  pagination,
}: Props<RecordType>) => {
  const total = pagination?.total ?? 0;

  return (
    <div css={tw`flex flex-col`}>
      {pagination && (
        <div
          css={tw`flex flex-row items-start justify-between mb-[16px] w-full`}
        >
          <Typography>총 {total.toLocaleString()}개</Typography>
        </div>
      )}

      <AntdTable
        scroll={{ x: '100%' }}
        tableLayout="auto"
        columns={columns}
        dataSource={dataSource}
        summary={summary}
        rowKey={rowKey}
        pagination={
          pagination
            ? {
                showQuickJumper: false,
                showSizeChanger: false,
                position: ['bottomCenter'],
                current: pagination.page,
                pageSize: pagination.size,
                total,
                onChange: (page, size) =>
                  pagination.setPaginationValues({
                    page,
                    size,
                  }),
              }
            : false
        }
      />
    </div>
  );
};

export default Table;
