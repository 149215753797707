import axios from 'axios';

import { LOCAL_STORAGE_KEYS } from '@/constants';

const defaultClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

defaultClient.interceptors.request.use((config) => {
  const newConfig = { ...config };

  const accessToken = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH) as string)
        .accessToken
    : '';

  if (accessToken && config.headers.Authorization !== null) {
    // 리프레시 토큰 api는 headers.Authorization을 주면 안돼서 명시적으로 null을 주지 않은 경우에만 Authorization 설정
    newConfig.headers.Authorization = `Bearer ${accessToken}`;
  }

  return newConfig;
});

export default defaultClient;
