import { Button, Empty } from 'antd';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import tw from 'twin.macro';

import { PATH, ROUTES } from '@/Route';
import { useMyPermissions } from '@/hooks/queries/partner';
import { authAtom } from '@/store/atoms';
import type { RouteKey } from '@/types/app';

const NotFound = () => {
  const { availableMenuKeys } = useMyPermissions();
  const navigate = useNavigate();
  const auth = useAtomValue(authAtom);

  useEffect(() => {
    if (auth.accessToken) {
      const firstAvailableRouteKey = Object.keys(ROUTES).find((key) =>
        availableMenuKeys.includes(key)
      ) as RouteKey;

      if (firstAvailableRouteKey) {
        navigate(ROUTES[firstAvailableRouteKey].path, {
          replace: true,
        });
      }
    } else {
      navigate(PATH.LOGIN, { replace: true });
    }
  }, [auth, availableMenuKeys]);

  return (
    <div
      css={tw`flex flex-col items-center justify-center gap-[20px] w-full h-full`}
    >
      <Empty description="페이지를 찾을 수 없습니다.">
        <Button type="primary" onClick={() => navigate(-1)}>
          뒤로가기
        </Button>
      </Empty>
    </div>
  );
};

export default NotFound;
