import { Typography } from 'antd';
import type { ReactNode } from 'react';
import tw from 'twin.macro';

interface Props {
  icon?: ReactNode;
  children: string;
}

const Notice = ({ icon, children }: Props) => {
  return (
    <div
      css={[
        tw`flex flex-row items-center gap-[8px] p-[16px 24px] bg-[#f5f5f5] rounded-[8px]`,
        tw`[svg]:(w-[24px] h-[24px])`,
      ]}
    >
      {icon}
      <Typography css={tw`flex-[1]`}>{children}</Typography>
    </div>
  );
};

export default Notice;
