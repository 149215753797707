import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/ko_KR';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { Provider, createStore, getDefaultStore } from 'jotai';
import { DevTools } from 'jotai-devtools';
import 'jotai-devtools/styles.css';

import { refreshToken } from '@/apis/partner';
import { authAtom } from '@/store/atoms';
import type { ErrorResponse } from '@/types/api';

import Route from './Route';
import './global.scss';

dayjs.locale('ko');

let refreshing = false;
let callbackQueue: Array<() => void> = [];
const handleExpireToken = async (error: Error, callback: () => void) => {
  if (isAxiosError<ErrorResponse>(error)) {
    if (error.response?.data.error === 'EXPIRED-TOKEN') {
      const store = getDefaultStore();

      try {
        if (!refreshing) {
          refreshing = true;
          const auth = store.get(authAtom);
          const newAuth = await refreshToken(auth.refreshToken);
          store.set(authAtom, newAuth);
          callback();
          while (callbackQueue.length > 0) {
            const firstCallback = callbackQueue.shift() as () => void;

            firstCallback();
          }
          refreshing = false;
        } else {
          callbackQueue.push(callback);
        }
      } catch {
        callbackQueue = [];
        store.set(authAtom, {
          accessToken: '',
          refreshToken: '',
        });
      }
    }
  }
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      handleExpireToken(error, () =>
        queryClient.refetchQueries({ queryKey: query.queryKey })
      );
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      handleExpireToken(error, () => {
        mutation?.options?.mutationFn?.(variables);
      });
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    },
    mutations: {
      retry: false,
    },
  },
});

const store = createStore();

const App = () => {
  return (
    <Provider store={store}>
      <DevTools store={store} />
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ConfigProvider
          locale={locale}
          theme={{
            hashed: false,
            token: {
              fontSizeLG: 14,
            },
            components: {
              Timeline: {
                dotBg: 'rgba(0, 0, 0, 0.15)',
                dotBorderWidth: 4,
                tailWidth: 1,
                itemPaddingBottom: 4,
              },
              Table: {
                cellPaddingBlock: 12,
              },
              Form: {
                itemMarginBottom: 16,
                verticalLabelPadding: '4px 0',
              },
              Button: {
                fontSizeLG: 16,
              },
            },
          }}
        >
          <Route />
        </ConfigProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
