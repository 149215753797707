import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

interface DefaultPagination {
  page: number;
  size: number;
}
type Value = string | number | boolean | null;

const DEFAULT_SIZE = 20;
const DEFAULT_PAGE = 1;

const usePagination = <AdditionalParams extends string>(
  keys: AdditionalParams[] = [] // 주소창 queryString에 사용할 키 배열
) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const getPagination = () => {
    const params = new URLSearchParams(search);

    return [
      ['page', params.get('page') ?? DEFAULT_PAGE],
      ['size', params.get('size') ?? DEFAULT_SIZE],
      ...keys.map<[string, string | undefined]>((key) => [
        key,
        params.get(key) ?? undefined,
      ]),
    ].reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]:
            value === 'true'
              ? true
              : value === 'false'
                ? false
                : !Number.isNaN(Number(value))
                  ? Number(value)
                  : value,
        };
      },
      {} as DefaultPagination & Partial<Record<AdditionalParams, Value>>
    );
  };

  const [pagination, setPagination] = useState(getPagination());

  useEffect(() => {
    setPagination(getPagination());
  }, [search]);

  const onPartialChange = (
    key: keyof DefaultPagination | AdditionalParams,
    value: Value
  ) => {
    onChange({
      [key]: value,
    } as Partial<DefaultPagination & Record<AdditionalParams, Value>>);
  };

  const onChange = (
    newValues: Partial<DefaultPagination & Record<AdditionalParams, Value>>
  ) => {
    const params = new URLSearchParams();

    Object.entries(pagination).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        params.set(key, String(value));
      }
    });

    Object.entries(newValues).forEach(([key, value]) => {
      if (value === null || value === undefined || value === '') {
        params.delete(key);
      } else {
        params.set(key, String(value));
      }
    });

    navigate({ search: params.toString() });
  };

  return {
    ...pagination,
    setPaginationValue: onPartialChange,
    setPaginationValues: onChange,
  };
};

export default usePagination;
