import { Badge, DatePicker, Select, Tag, Timeline, Typography } from 'antd';
import dayjs from 'dayjs';
import tw from 'twin.macro';

import { NoticeIcon } from '@/assets/svg';
import Notice from '@/components/Notice';
import Table from '@/components/Table';
import TooltipText from '@/components/TooltipText';
import { CONTRACT_STATUS, CUSTOMER_TYPE } from '@/constants/contract';
import { UNIT_TYPE } from '@/constants/unit';
import usePagination from '@/hooks/usePagination';

const ContractIndex = () => {
  const pagination = usePagination([
    'contractStatus',
    'userType',
    'contractStartAtStart',
    'contractStartAtEnd',
    'contractEndAtStart',
    'contractEndAtEnd',
  ]);

  return (
    <div css={tw`flex flex-col gap-[32px]`}>
      <Notice icon={<NoticeIcon />}>
        할인율은 정가 기준에서 적용된 할인율입니다. 다이나믹 프라이싱, 프로모션
        적용에 따라 할인율이 달라집니다. 프로모션은 쿠폰 적용 여부를 표시합니다.
      </Notice>

      <div
        css={[
          tw`flex flex-row items-center justify-between gap-[12px] flex-wrap overflow-x-auto`,
          tw`mobile:(flex-col items-start)`,
        ]}
      >
        <div css={tw`flex flex-row items-center gap-[12px] w-max`}>
          <Select
            placeholder="계약 상태"
            value={pagination.contractStatus}
            onChange={(value) =>
              pagination.setPaginationValue('contractStatus', value)
            }
          >
            {Object.entries(CONTRACT_STATUS).map(([value, label]) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
          <Select
            placeholder="회원 유형"
            value={pagination.userType}
            onChange={(value) =>
              pagination.setPaginationValue('userType', value)
            }
          >
            {Object.entries(CUSTOMER_TYPE).map(([value, label]) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div css={tw`flex flex-row items-center gap-[12px] w-max`}>
          <DatePicker.RangePicker
            picker="date"
            placeholder={['계약 시작일', '계약 시작일']}
            value={[
              pagination.contractStartAtStart
                ? dayjs(pagination.contractStartAtStart as string)
                : null,
              pagination.contractStartAtEnd
                ? dayjs(pagination.contractStartAtEnd as string)
                : null,
            ]}
            onChange={(_, [startDate, endDate]) => {
              pagination.setPaginationValues({
                contractStartAtStart: startDate,
                contractStartAtEnd: endDate,
              });
            }}
            presets={[
              { label: '1주일', value: [dayjs().subtract(1, 'week'), dayjs()] },
              {
                label: '1개월',
                value: [dayjs().subtract(1, 'month'), dayjs()],
              },
              {
                label: '3개월',
                value: [dayjs().subtract(3, 'month'), dayjs()],
              },
              {
                label: '6개월',
                value: [dayjs().subtract(6, 'month'), dayjs()],
              },
            ]}
          />
          <DatePicker.RangePicker
            picker="date"
            placeholder={['계약 종료일', '계약 종료일']}
            value={[
              pagination.contractEndAtStart
                ? dayjs(pagination.contractEndAtStart as string)
                : null,
              pagination.contractEndAtEnd
                ? dayjs(pagination.contractEndAtEnd as string)
                : null,
            ]}
            onChange={(_, [startDate, endDate]) => {
              pagination.setPaginationValues({
                contractEndAtStart: startDate,
                contractEndAtEnd: endDate,
              });
            }}
            presets={[
              { label: '1주일', value: [dayjs().subtract(1, 'week'), dayjs()] },
              {
                label: '1개월',
                value: [dayjs().subtract(1, 'month'), dayjs()],
              },
              {
                label: '3개월',
                value: [dayjs().subtract(3, 'month'), dayjs()],
              },
              {
                label: '6개월',
                value: [dayjs().subtract(6, 'month'), dayjs()],
              },
            ]}
          />
        </div>
      </div>

      <Table
        columns={[
          { title: 'No', dataIndex: 'id' },
          {
            title: '유닛 타입',
            dataIndex: 'unitType',
            render: (value) => UNIT_TYPE[value as keyof typeof UNIT_TYPE],
          },
          { title: '유닛 번호', dataIndex: 'unitName' },
          {
            title: '계약 상태',
            dataIndex: 'contractStatus',
            render: (value, record) => (
              <Badge
                status={(() => {
                  switch (record.contractStatus) {
                    case 'RESERVED':
                      return 'success';
                    case 'NOT_PAID':
                    case 'CANCELED':
                      return 'error';
                    case 'CONTRACT_END':
                      return 'warning';
                    default:
                      return 'default';
                  }
                })()}
                text={CONTRACT_STATUS[value as keyof typeof CONTRACT_STATUS]}
              />
            ),
          },
          {
            title: (
              <TooltipText
                description={
                  '계약 시작일: 사용자가 계약을 체결한 날짜\n이용 시작일: 유닛을 사용하기로 한 날짜'
                }
              >
                계약 / 이용일
              </TooltipText>
            ),
            dataIndex: 'period',
            render: (_, record) => {
              const timelineItems = [
                {
                  children: (
                    <>
                      <Typography.Text type="secondary">
                        계약 시작일
                      </Typography.Text>
                      {dayjs(record.contractStartAt).format('YYYY-MM-DD')}
                    </>
                  ),
                },
                record.usageStartAt && {
                  children: (
                    <>
                      <Typography.Text type="secondary">
                        이용 시작일
                      </Typography.Text>
                      {dayjs(record.usageStartAt).format('YYYY-MM-DD')}
                    </>
                  ),
                },
                {
                  children: (
                    <>
                      <Typography.Text type="secondary">
                        계약 종료일
                      </Typography.Text>
                      {dayjs(record.contractEndAt).format('YYYY-MM-DD')}
                    </>
                  ),
                },
                record.usageEndAt && {
                  children: (
                    <>
                      <Typography.Text type="secondary">
                        이용 종료일
                      </Typography.Text>
                      {dayjs(record.usageEndAt).format('YYYY-MM-DD')}
                    </>
                  ),
                },
              ].filter((item) => !!item);

              return (
                <div
                  css={tw`[.ant-timeline-item-content]:(flex flex-row gap-[12px])`}
                >
                  <Timeline items={timelineItems} />
                </div>
              );
            },
          },
          {
            title: '회원 유형',
            dataIndex: 'userType',
            align: 'center',
            render: (value) => (
              <Tag>{CUSTOMER_TYPE[value as keyof typeof CUSTOMER_TYPE]}</Tag>
            ),
          },
          { title: '정가', dataIndex: 'originPrice', align: 'right' },
          { title: '할인율', dataIndex: 'discountRate', align: 'right' },
          { title: '판매가', dataIndex: 'price', align: 'right' },
          {
            title: '프로모션',
            dataIndex: 'hasPromotion',
            align: 'center',
            render: (value) => <Tag>{value ? '적용' : '미적용'}</Tag>,
          },
        ]}
        dataSource={[
          {
            id: 1,
            unitType: 'MINI',
            unitName: 'M1',
            contractStatus: 'RESERVED',
            contractStartAt: '2024-11-28 00:00:00',
            contractEndAt: '2024-11-29 23:59:59',
            usageStartAt: '2024-11-28 00:00:00',
            usageEndAt: '2024-11-29 23:59:59',
            userType: 'NORMAL',
            originPrice: 100000,
            discountRate: 10,
            price: 90000,
            hasPromotion: true,
          },
          {
            id: 2,
            unitType: 'LARGE',
            unitName: 'L1',
            contractStatus: 'CANCELED',
            contractStartAt: '2024-11-28 00:00:00',
            contractEndAt: '2024-11-29 23:59:59',
            usageStartAt: '2024-11-28 00:00:00',
            usageEndAt: '2024-11-29 23:59:59',
            userType: 'BUSINESS',
            originPrice: 100000,
            discountRate: 10,
            price: 90000,
            hasPromotion: true,
          },
          {
            id: 3,
            unitType: 'SMALL',
            unitName: 'S1',
            contractStatus: 'CONTRACT_END',
            contractStartAt: '2024-11-28 00:00:00',
            contractEndAt: '2024-11-29 23:59:59',
            usageStartAt: null,
            usageEndAt: null,
            userType: 'NORMAL',
            originPrice: 100000,
            discountRate: 10,
            price: 90000,
            hasPromotion: false,
          },
          {
            id: 4,
            unitType: 'CUBE',
            unitName: 'C1',
            contractStatus: 'NOT_PAID',
            contractStartAt: '2024-11-28 00:00:00',
            contractEndAt: '2024-11-29 23:59:59',
            usageStartAt: null,
            usageEndAt: null,
            userType: 'BUSINESS',
            originPrice: 100000,
            discountRate: 10,
            price: 90000,
            hasPromotion: false,
          },
        ]}
        rowKey={(row) => row.id}
        pagination={{
          setPaginationValues: pagination.setPaginationValues,
          page: pagination.page,
          size: pagination.size,
          total: 500,
        }}
      />
    </div>
  );
};

export default ContractIndex;
