import type { Pagination } from '@/types/api';
import type {
  Adjustment,
  BranchShare,
  GetBranchSharesParams,
  GetMyInformationResponse,
  GetMyPermissionsResponse,
  GetUnitOperationRateResponse,
  LoginParams,
  LoginResponse,
  UnitCompositionRate,
} from '@/types/partner';
import axios from '@/utils/axios';

export const login = ({
  email,
  password,
}: LoginParams): Promise<LoginResponse> => {
  return axios
    .post('/partner/v1/users/login', {
      email,
      password,
    })
    .then((res) => res.data);
};

export const refreshToken = (refreshToken: string): Promise<LoginResponse> => {
  return axios
    .put(
      '/partner/v1/users/token',
      { refreshToken },
      {
        headers: {
          Authorization: null,
        },
      }
    )
    .then((res) => res.data);
};

export const getMyInformation = (): Promise<GetMyInformationResponse> => {
  return axios.get('/partner/v1/users/me').then((res) => res.data);
};

export const getMyPermissions = (): Promise<GetMyPermissionsResponse> => {
  return axios.get('/partner/v1/users/permissions').then((res) => res.data);
};

export const getBranchShares = ({
  branchId,
  page,
  size,
  recordedAtAfter,
  recordedAtBefore,
}: GetBranchSharesParams): Promise<Pagination<BranchShare>> => {
  return axios
    .get(`/partner/v1/stats/units/${branchId}/shares`, {
      params: {
        page,
        size,
        recordedAtAfter,
        recordedAtBefore,
      },
    })
    .then((res) => res.data);
};

export const getUnitOperationRates = (
  branchId: number
): Promise<GetUnitOperationRateResponse> => {
  return axios
    .get(`/partner/v1/stats/units/${branchId}/operation-rates`)
    .then((res) => res.data);
};

export const getUnitCompositionRates = (
  branchId: number
): Promise<UnitCompositionRate[]> => {
  return axios
    .get(`/partner/v1/stats/units/${branchId}/composition-rates`)
    .then((res) => res.data);
};

export const getAdjustments = (): Promise<Adjustment[]> => {
  return axios.get(`/partner/v1/branches/adjustments`).then((res) => res.data);
};
