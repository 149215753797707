export const UNIT_TYPE = {
  MINI: '미니',
  COMPACT: '컴팩트',
  CUBE: '큐브',
  SLIM: '슬림',
  SMALL: '스몰',
  MEDIUM: '미디움',
  LARGE: '라지',
  CUBE_PLUS: '큐브플러스',
  SLIM_PLUS: '슬림플러스',
  SMALL_PLUS: '스몰플러스',
  MEDIUM_PLUS: '미디움플러스',
  LARGE_PLUS: '라지플러스',
  ETC: '기타',
};
