import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Drawer as AntdDrawer, Button, Layout, Select, Typography } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import tw from 'twin.macro';

import { PATH } from '@/Route';
import { PartnersLogo } from '@/assets/svg';
import { useMyInformation } from '@/hooks/queries/partner';
import useIsWindowWidthBelowThan from '@/hooks/useIsWindowWidthBelowThan';
import { authAtom, selectedBranchIdAtom } from '@/store/atoms';

import Account from './Account';
import Menu from './Menu';

interface Props {
  title: string;
  useBranchSelector?: boolean;
  children?: ReactNode;
}

const AuthenticatedLayout = ({ title, useBranchSelector, children }: Props) => {
  const getMyInformation = useMyInformation();
  const navigate = useNavigate();
  const auth = useAtomValue(authAtom);
  const [selectedBranchId, setSelectedBranchId] = useAtom(selectedBranchIdAtom);
  const isMobile = useIsWindowWidthBelowThan(768);
  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if (!auth.accessToken) {
      navigate(PATH.LOGIN, { replace: true });
    }
  }, [auth]);

  useEffect(() => {
    if (selectedBranchId === null && getMyInformation.data?.branches[0]) {
      setSelectedBranchId(getMyInformation.data.branches[0].id);
    }
  }, [getMyInformation.data]);

  useEffect(() => {
    if (!isMobile) {
      setDrawer(false);
    }
  }, [isMobile]);

  return (
    <Layout>
      <Drawer
        placement="left"
        width={280}
        onClose={() => setDrawer(false)}
        open={drawer}
      >
        <Menu />

        <div css={[tw`flex flex-col gap-[24px] mt-[64px]`]}>
          <Button
            type="default"
            block
            icon={<LogoutOutlined />}
            onClick={() => navigate(PATH.LOGOUT)}
          >
            로그아웃
          </Button>
          <Account />
        </div>
      </Drawer>

      <Layout.Sider
        hidden={isMobile}
        css={[
          tw`p-[40px 32px] overflow-y-auto bg-[#edeef1]`,
          tw`[.ant-layout-sider-children]:(flex flex-col justify-between gap-[40px] h-[unset] min-h-full)`,
          tw`[& > svg]:(shrink-0 w-[112px] h-[18px])`,
        ]}
        width={280}
        collapsed={false}
        collapsedWidth={0}
      >
        <Menu />

        <PartnersLogo />
      </Layout.Sider>

      <Layout>
        <Layout.Header
          css={[
            tw`flex flex-row justify-end items-center h-[64px] gap-[8px] p-[16px 40px]`,
            tw`bg-white border-b-[1px] border-b-[#f0f0f0]`,
            tw`mobile:(justify-start p-[12px 20px] h-[48px] border-none)`,
          ]}
        >
          {isMobile ? (
            <button
              css={tw`flex items-center justify-center w-[24px] h-[24px] p-0 text-[24px] text-[rgba(0, 0, 0, 0.45)]`}
              type="button"
              onClick={() => setDrawer(true)}
            >
              <MenuOutlined />
            </button>
          ) : (
            <>
              <Account />
              <Button
                type="text"
                icon={<LogoutOutlined />}
                onClick={() => navigate(PATH.LOGOUT)}
              >
                로그아웃
              </Button>
            </>
          )}
        </Layout.Header>

        <Layout.Content
          css={[
            tw`flex flex-col p-[48px 40px 0]`,
            tw`mobile:(px-[20px])`,
            tw`bg-white`,
          ]}
        >
          <div
            css={tw`flex flex-row items-start justify-between mb-[32px] w-full`}
          >
            <Typography.Text css={tw`text-[24px] font-semibold leading-[32px]`}>
              {title}
            </Typography.Text>

            {useBranchSelector &&
              getMyInformation.data &&
              getMyInformation.data.branches.length > 1 && (
                <Select
                  placeholder="지점 선택"
                  value={selectedBranchId}
                  onChange={(value) => {
                    setSelectedBranchId(value);
                  }}
                  style={{ width: 160 }}
                >
                  {getMyInformation.data.branches.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
          </div>

          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

const Drawer = styled(AntdDrawer)`
  && {
    ${tw`min-w-[280px] bg-white`}

    .ant-drawer-header {
      ${tw`p-[12px 20px] border-none`}

      .ant-drawer-header-title {
        ${tw`flex flex-row justify-end`}
      }

      button {
        ${tw`m-0`}
      }
    }

    .ant-drawer-body {
      ${tw`p-[36px 20px]`}
    }
  }
`;

export default AuthenticatedLayout;
