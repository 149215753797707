import { atomWithStorage } from 'jotai/utils';

import { LOCAL_STORAGE_KEYS } from '@/constants';

export const authAtom = atomWithStorage<{
  accessToken: string;
  refreshToken: string;
}>(
  LOCAL_STORAGE_KEYS.AUTH,
  localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH)
    ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH) as string)
    : {
        accessToken: '',
        refreshToken: '',
      }
);

export const selectedBranchIdAtom = atomWithStorage<number | null>(
  LOCAL_STORAGE_KEYS.SELECTED_BRANCH_ID,
  null
);
