import { Button, Form } from 'antd';
import { useEffect, useState } from 'react';
import tw from 'twin.macro';

interface Props {
  isLoading: boolean;
  children: string;
}

const Submit = ({ isLoading, children }: Props) => {
  const form = Form.useFormInstance();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 1000);
  });

  return (
    <Button
      css={tw`mt-[16px]`}
      type="primary"
      htmlType="submit"
      size="large"
      block
      loading={isLoading}
      disabled={
        !active ||
        !form.isFieldsTouched(true) ||
        !!form.getFieldsError().filter(({ errors }) => errors.length).length
      }
    >
      {children}
    </Button>
  );
};

export default Submit;
